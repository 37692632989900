import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Pokemon from "../components/pokemon"

const PokemonListPage = ({ data, location }) => {
  const [search, setSearch] = React.useState("")
  const rawPokemons = data.allMarkdownRemark.edges
  const [pokemons, setPokemons] = React.useState(rawPokemons)
  const [isLoading, setIsLoading] = React.useState(true)
  const siteTitle = data.site.siteMetadata.title

  React.useLayoutEffect(() => {
    setPokemons(
      rawPokemons.filter(pokemon => {
        if (!search || search.length <= 2) {
          return true
        } else {
          return pokemon.node.frontmatter.name.toLowerCase().includes(search)
        }
      })
    )
  }, [search])

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Bug Type Pokémon GO"
        description="Everything you need to know about Bug type Pokémons in Pokémon GO: who are they? How many Bug Pokémon? What are their Weaknesses? How powerful are they?"
        slug={location.pathname}
      />

      <h1>Bug Type Pokémon GO</h1>

      <p>There are {pokemons.length} bug types in Pokemon GO.</p>

      <p>
        As seen on our{" "}
        <Link to="/pokemon-go-type-chart/">Pokémon GO Type Chart</Link>, bug type
        Pokémons are weak against Fire, Flying and Rock and resistant to
        Fighting, Grass and Ground attacks.
      </p>

      <h2>Best Bug Type Pokemon GO</h2>

      <p>Here is a list of all the Bug Type Pokemons currently implemented in Pokemon GO:</p>

      <input
        value={search}
        onChange={e => setSearch(e.target.value)}
        style={{ width: "100%" }}
        placeholder="Search for a Bug Type Pokemon..."
      />

      <div>
        {pokemons.map(pokemon => {
          return (
            <Link
              key={`${pokemon.node.frontmatter.id}-${pokemon.node.frontmatter.form}`}
              to={`/pokemon-go/${
                pokemon.node.frontmatter.id
              }-${pokemon.node.frontmatter.name.toLowerCase()}/`}
            >
              <Pokemon pokemon={pokemon.node.frontmatter} />
            </Link>
          )
        })}
      </div>
    </Layout>
  )
}

export default PokemonListPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___id], order: ASC }
      filter: {
        fileAbsolutePath: {
          regex: "/.*/pokemon/(10|11|12|13|14|15|46|47|48|49|123|127|165|166|167|168|193|204|205|212|213|214|265|266|267|268|269|283|284|290|291|292|313|314|347|348|401|402|412|413|414|415|416|451|469|540|541|542|543|544|545|557|558|588|589|595|596|616|617|632|636|637|649)-.*/"
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            id
            name
            form
            type1
            type2
            maxCp
            image {
              publicURL
            }
          }
        }
      }
    }
  }
`
